<template>
  <div class="flexible zhipin">
    <Menu :title="title" />
    <div class="product-banner">
        <div class="banner-i clearfix">
            <img src="@/assets/images/banner/flexible.jpg" />
            <div class="banner-con">
                <div class="line1">灵活用工</div>
                <div class="line2">灵活用工个平台、灵活就业人员接单，业务交易、结算平台。</div>
                <div class="line3"><a href="https://flex.zhichangcloud.com/#/login">现在去用</a></div>
            </div>
        </div>
    </div>
    <section>
        <div class="act_x">
            <div>
                <div class="wrap rly_01">
                    <ul>
                        <li class="l-body bg-white">
                           
                            <div class="sfl_02_cell sfl_02_txt" style="padding-top:.5rem;margin-bottom:0">
                                <h3 class="p1">什么是灵活用工？</h3>
                                <p class="p2" style="text-indent:1.7em;">
                                    <i>灵活用工是指企业基于经营需要或业务需求，通过短期或项目型用工方式，实现企业人力资源队伍的快速 调整、精确匹配、弹性管理。传统用工方式，人员成本高，企业负担重，阶段性用工需求波动大，低需求 期用工成本浪费，灵活用工平台全面助力企业降本增效，聚焦企业核心业务，提高内部人员效能，让企业 轻装运营。</i>
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>

    <section>
        <div class="act_x" style="padding-top:0;">
            <div class="act_x_t size-40 color-333 l-title">企业为什么采用灵活用工？</div>
            <div class="title_down_line l-title">&nbsp;</div>
            <div class="l-body">
                <ul class="clearfix hxld">
                    <li data-aos="fade-left" data-aos-delay="600" class="clearfix">
                        <div class="img-container">
                            <img src="@/assets/images/product/flexible/tongdian1.png">
                        </div>
                        <div class="text-container">
                            <h3><span>成本压力大</span></h3>
                            <p>传统用工方式，人员成本高，企
                            业负担重，阶段性用工需求波动
                            大，低需求期用工成本浪费</p>
                        </div>
                        
                    </li>
                    <li data-aos="fade-left" data-aos-delay="1200" class="clearfix">
                        <div class="img-container fright">
                            <img src="@/assets/images/product/flexible/tongdian2.png">
                        </div>
                        <div class="text-container fleft">
                            <h3><span>员工流动大</span></h3>
                            <p>一线人员流动大、流失率高。</p>
                        </div>
                        
                    </li>
                    <li data-aos="fade-left" data-aos-delay="1800" class="clearfix">
                        <div class="img-container">
                            <img src="@/assets/images/product/flexible/tongdian3.png">
                        </div>
                        <div class="text-container">
                            <h3><span>支付高风险</span></h3>
                            <p>对私账支付风险高，监管严。</p>
                        </div>
                        
                    </li>
                    <li data-aos="fade-left" data-aos-delay="2400" class="clearfix">
                        <div class="img-container fright">
                            <img src="@/assets/images/product/flexible/tongdian4.png">
                        </div>
                        <div class="text-container fleft">
                            <h3><span>个人无发票</span></h3>
                            <p>
                                个人、劳务用工无法提供发票。
                            </p>
                        </div>    
                    </li>
                    <li data-aos="fade-left" data-aos-delay="3000" class="clearfix">
                        <div class="img-container">
                            <img src="@/assets/images/product/flexible/tongdian5.png">
                        </div>
                        <div class="text-container">
                            <h3><span>用工合规难</span></h3>
                            <p>季节性、临时性用工合规难。</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
	</section>

   <section>
        <div class="act_x">
            <div class="act_x_t size-40 color-333 l-title">灵活用工解决方案</div>
            <div class="title_down_line l-title">&nbsp;</div>
            <div class="act_x_desc l-title">有效实现企业降本增效！</div>
            <div class="l-body">
                <div class="flex-solution">
                    <div class="l-con">
                        <div class="img-container " data-aos="fade-right" data-aos-delay="300">
                            <img src="@/assets/images/product/flexible/fangan1.png">
                        </div>
                        <div class="text-container " data-aos="fade-left" data-aos-delay="600">
                            <div>
                                <div class="text1">
                                    <span class="num">01</span>
                                    <span class="stri">所解决的问题</span></div>
                                <div class="text2"><span class="circle">•</span><span>调整人员结构、降低人力成本，降低税负；</span></div>
                                <div class="text2"><span class="circle">•</span><span>改变用工模式，降低用人风险；</span></div>
                            </div>

                        </div>
                    </div>
                    <div class="l-con">
                        <div class="img-container" data-aos="fade-left" data-aos-delay="1200">
                            <img src="@/assets/images/product/flexible/fangan2.png">
                        </div>
                        <div class="text-container " data-aos="fade-right" data-aos-delay="900">
                            <div>
                                <div class="text1">
                                    <span class="num">02</span>
                                    <span class="stri">优势</span></div>
                                <div class="text2"><span class="circle">•</span><span>灵活用工高效省时、合法合规、安全快捷。</span></div>
                                
                            </div>

                        </div>
                        

                    </div>
                    <div class="l-con">
                        <div class="img-container " data-aos="fade-right" data-aos-delay="1200">
                            <img src="@/assets/images/product/flexible/fangan3.png">
                        </div>
                        <div class="text-container " data-aos="fade-left" data-aos-delay="1500">
                            <div>
                                <div class="text1">
                                    <span class="num">03</span>
                                    <span class="stri">建立完整服务流程</span></div>
                                <div class="text2"><span class="circle">•</span><span>建立了完整服务流程规范体系与执行标准，通过流程核验与系统筛查，</span></div>
                                <div class="text2"><span class="circle">•</span><span>确保业务合理合规，有效降低风险，提供安全可靠的服务。</span></div>
                                
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
	</section>

   
    <Bottom />
    
  </div>
</template>
<script>
import Bottom from '../components/Bottom'
import ProductBanner from '../components/ProductBanner'
import Menu from '../components/Menu'
export default {
    name:'Flexible',
    data() {
      return {
        title:'灵活用工',
        bhref:'#'
      }
    },
    computed: {
     
    },
    components: {
        Bottom,
        ProductBanner,
        Menu
    },
    directives: {

    },
     mounted() {
    }
}
    
</script>
<style>

</style>
